//Reducer for character information Initialize State
const initState = {
      resm_basic_page:"Home",
      resm_basic_m1:"1",
      resm_basic_m2:"1",
      resm_basic_m3:"1",
      resm_basic_m4:"1",
      resm_basic_m5:"1",
      resm_basic_m6:"1",
      resm_basic_m7:"1",
      resm_basic_topspace:"",
      resm_basic_name: "Sathish",
      resm_basic_add1: "24",
      resm_basic_add2: "New street",
      resm_basic_add3:"Big Garden",
      resm_basic_add4:"Chennai - 600012",
      resm_basic_email:"sathish@gmail.com",
      resm_basic_mobile:"1234567890",
      resm_basic_objective:"here objective",
      resm_basic_edu_lists:"testlist",
      resm_basic_additional_qual:"",
      resm_basic_key_strengths:"",
      resm_basic_per_dob:"heredob",
      resm_basic_per_father_name:"",
      resm_basic_per_mother_name:"",
      resm_basic_per_nationality:"",
      resm_basic_per_gender:"",
      resm_basic_per_lang_know:"",
      resm_basic_per_marital_status:"",
      resm_basic_per_hobbies:"hhhh",
      resm_basic_declaration:"",
      resm_basic_place:"",
      pdfhtml:"",
}

//Define Actions
const resumeReducer = (state = initState, action) => {
      
      if(action.type === "RES_BASIC_NAME"){
            return {
                ...state,
                resm_basic_name: action.payload
            }
      } else if(action.type === "RES_BASIC_ADD1"){
            return {
                    ...state,
                    resm_basic_add1: action.payload
                } 
          } else if(action.type === "RES_BASIC_ADD2"){
            return {
                    ...state,
                    resm_basic_add2: action.payload
                } 
          } else if(action.type === "RES_BASIC_ADD3"){
            return {
                    ...state,
                    resm_basic_add3: action.payload
                } 
          } else if(action.type === "RES_BASIC_ADD4"){
            return {
                    ...state,
                    resm_basic_add4: action.payload
                } 
          } else if(action.type === "RES_BASIC_EMAIL"){
            return {
                    ...state,
                    resm_basic_email: action.payload
                } 
          } else if(action.type === "RES_BASIC_MOBILE"){
            return {
                    ...state,
                    resm_basic_mobile: action.payload
                } 
          } else if(action.type === "RES_BASIC_OBJECTIVE"){
            return {
                    ...state,
                    resm_basic_objective: action.payload
                } 
          } else if(action.type === "RES_BASIC_EDU_LIST"){
            return {
                    ...state,
                    resm_basic_edu_lists: action.payload
                } 
          } else if(action.type === "RES_BASIC_ADD_QUALIFICATION"){
            return {
                    ...state,
                    resm_basic_additional_qual: action.payload
                } 
          } else if(action.type === "RES_BASIC_ADD_STRENGTH"){
            return {
                    ...state,
                    resm_basic_key_strengths: action.payload
                } 
          } else if(action.type === "RES_BASIC_DOB"){
            return {
                    ...state,
                    resm_basic_per_dob: action.payload
                } 
          } else if(action.type === "RES_BASIC_FATHERNAME"){
            return {
                    ...state,
                    resm_basic_per_father_name: action.payload
                } 
          } else if(action.type === "RES_BASIC_MOTHERNAME"){
            return {
                    ...state,
                    resm_basic_per_mother_name: action.payload
                } 
          } else if(action.type === "RES_BASIC_LANG_KNOWN"){
            return {
                    ...state,
                    resm_basic_per_lang_know: action.payload
                } 
          } else if(action.type === "RES_BASIC_NATIONALITY"){
            return {
                    ...state,
                    resm_basic_per_nationality: action.payload
                } 
          } else if(action.type === "RES_BASIC_GENDER"){
            return {
                    ...state,
                    resm_basic_per_gender: action.payload
                } 
          } else if(action.type === "RES_BASIC_MARITAL"){
            return {
                    ...state,
                    resm_basic_per_marital_status: action.payload
                } 
          } else if(action.type === "RES_BASIC_HOBBIES"){
            return {
                    ...state,
                    resm_basic_per_hobbies: action.payload
                } 
          } else if(action.type === "RES_BASIC_DECLARATION"){
            return {
                    ...state,
                    resm_basic_declaration: action.payload
                } 
          } else if(action.type === "RES_BASIC_PLACE"){
            return {
                    ...state,
                    resm_basic_place: action.payload
                } 
          } else if(action.type === "SET_HTML"){
            return {
                    ...state,
                     pdfhtml: action.payload
                } 
          } else if(action.type === "RES_BASIC_M1"){
            return {
                    ...state,
                    resm_basic_m1: action.payload
                } 
          } else if(action.type === "RES_BASIC_M2"){
            return {
                    ...state,
                    resm_basic_m2: action.payload
                } 
          } else if(action.type === "RES_BASIC_M3"){
            return {
                    ...state,
                    resm_basic_m3: action.payload
                } 
          } else if(action.type === "RES_BASIC_M4"){
            return {
                    ...state,
                    resm_basic_m4: action.payload
                } 
          } else if(action.type === "RES_BASIC_M5"){
            return {
                    ...state,
                    resm_basic_m5: action.payload
                } 
          } else if(action.type === "RES_BASIC_M6"){
            return {
                    ...state,
                    resm_basic_m6: action.payload
                } 
          } else if(action.type === "RES_BASIC_M7"){
            return {
                    ...state,
                    resm_basic_m7: action.payload
                } 
          } else if(action.type === "RES_BASIC_PAGE"){
            return {
                    ...state,
                    resm_basic_page: action.payload
                } 
          } else if(action.type === "RES_BASIC_TOP_SPACE"){
            return {
                    ...state,
                    resm_basic_topspace: action.payload
                } 
          } else {
               return state
            }
            
      
    //   switch (action.type) {
    //     case 'CHANGE_NAME':
    //         return {
    //             ...state,
    //             name: action.payload
    //         }

    //     case 'CHANGE_OCCUPATION':
    //         return {
    //             ...state,
    //             occupation: action.payload
    //         }
    //     case 'CHANGE_AGE':
    //         return {
    //             ...state,
    //             age: action.payload
    //         }
    //     default:
    //         return state
    // }
}

export default resumeReducer;