//Reducer for character information Initialize State
const initState = {
    ismenu:"",
    clist:"",

}

//Define Actions
const classReducer = (state = initState, action) => {
      
      if(action.type === "RES_CHANGE_CLASS"){
            return {
                ...state,
                ismenu: action.payload
            }
      } else if(action.type === "CHANGE_LISTSS"){
            return {
                    ...state,
                    clist: action.payload
                } 
          } else {
               return state
            }
      
}

export default classReducer;