import React, { Component, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import ReactSimplyCarousel from 'react-simply-carousel';

const Home = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [activeSlideIndex_two, setActiveSlideIndex_two] = useState(0);

    useEffect(() => {
        $( document ).ready(function() {
          $(".pageheads").html("Home");
      });
      }, []);


    useEffect(() => {

    });




  return(
  <div className='pagetp'>


<div className='row'>
<div className='col-12'>
<div className='headwel'>
<div className='weldata'>
Welcome, <span>Dominic</span>
</div>
<div className='weldatastreak'>
<div className=''>
<img src={'images/podium/bolt.png'} alt="" class="menu-img" />
</div>
<div className=''>
    <span>0</span> <span>day streak</span>
</div>
</div>
</div>
</div>
</div>



<div className='row mt-5'>
<div className='col-12'>
<div className='headtuto'>
Tutorials
    </div>
    </div>
</div>
<div  className="">
            <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={3}
        itemsToScroll={3}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 30,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 30,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
      </ReactSimplyCarousel>
            </div>

<div className='row mt-5'>
<div className='col-12'>
<div className='headtuto'>
Today's featured speeches
    </div>
    </div>
</div>
            <div className="">
            <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex_two}
        onRequestChange={setActiveSlideIndex_two}
        itemsToShow={3}
        itemsToScroll={3}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 30,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 30,
            lineHeight: 1,
            textAlign: 'center',
            width: 30,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/w1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
        <div className='cbox_parent'>
        <div className='cbox'>
        <div className='c_imgbox'>
        <img src={'images/carousel/m1.png'} alt="" class="menu-img" />
        </div>
        <div className='c_txtbox'>
        AI Analytics on Speech or Interview
        </div>
        </div>
        </div>
      </ReactSimplyCarousel>
            </div>
           </div>


  );
};

export default Home;