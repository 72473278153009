//Reducer for character information Initialize State
const initState = {
    name: "Sathish",
    occupation: "Operator",
    age: "49",
    pdfhtml:"tesrt",
    imagesixfour:"",
    height:"d",
    weight:"",
    color:"",
    brothercount:"",
    sistercount:"",
    dob:"",
    time:"",
    language:"",
    clist:"",

}

//Define Actions
const characterReducer = (state = initState, action) => {
      
      if(action.type === "CHANGE_LANGUAGES"){
            return {
                ...state,
                 language: action.payload
            }
      } else if(action.type === "CHANGE_NAME"){
            return {
                ...state,
                 name: action.payload
            }
      }else if(action.type === "CHANGE_OCCUPATION"){
        return {
                ...state,
                 occupation: action.payload
            }
      } else if(action.type === "CHANGE_AGE"){
        return {
                ...state,
                 age: action.payload
            } 
      } else if(action.type === "SET_HTML"){
            return {
                    ...state,
                     pdfhtml: action.payload
                } 
          } else if(action.type === "SET_IMAGE"){
            return {
                    ...state,
                     imagesixfour: action.payload
                } 
          } else if(action.type === "CHANGE_WEIGHT"){
            return {
                    ...state,
                     weight: action.payload
                } 
          } else if(action.type === "CHANGE_HEIGHT"){
            return {
                    ...state,
                     height: action.payload
                } 
          } else if(action.type === "CHANGE_COLOR"){
            return {
                    ...state,
                     color: action.payload
                } 
          }  else if(action.type === "CHANGE_DOB"){
            return {
                    ...state,
                     dob: action.payload
                } 
          } else if(action.type === "CHANGE_TIME"){
            return {
                    ...state,
                     time: action.payload
                } 
          } else if(action.type === "CHANGE_BCOUNT"){
            return {
                    ...state,
                    brothercount: action.payload
                } 
          } else if(action.type === "CHANGE_SCOUNT"){
            return {
                    ...state,
                    sistercount: action.payload
                } 
          }  else if(action.type === "CHANGE_FATHERNAME"){
            return {
                    ...state,
                    fathername: action.payload
                } 
          }  else if(action.type === "CHANGE_MOTHERNAME"){
            return {
                    ...state,
                    mothername: action.payload
                } 
          } else if(action.type === "CHANGE_FATHEROCC"){
            return {
                    ...state,
                    fatherocc: action.payload
                } 
          }  else if(action.type === "CHANGE_MOTHEROCC"){
            return {
                    ...state,
                    motherocc: action.payload
                } 
          } else if(action.type === "CHANGE_HIGH_QUALI"){
            return {
                    ...state,
                    education: action.payload
                } 
          } else if(action.type === "CHANGE_COMPANYNAME"){
            return {
                    ...state,
                    companyname: action.payload
                } 
          }  else if(action.type === "CHANGE_JOB_TITLE"){
            return {
                    ...state,
                    jobtitle: action.payload
                } 
          }  else if(action.type === "CHANGE_MON_SALARY"){
            return {
                    ...state,
                    monsalary: action.payload
                } 
          }  else if(action.type === "CHANGE_COM_ADDRESS"){
            return {
                    ...state,
                    address: action.payload
                } 
          }  else if(action.type === "CHANGE_PART_PRE"){
            return {
                    ...state,
                    partnerpreferences: action.payload
                } 
          }  else if(action.type === "CHANGE_ABOUT"){
            return {
                    ...state,
                    about: action.payload
                } 
          }   else if(action.type === "CHANGE_OTH_DETAILS"){
            return {
                    ...state,
                    otherdetails: action.payload
                } 
          }  else if(action.type === "CHANGE_HOROSCOPE"){
            return {
                    ...state,
                    rasi: action.payload
                } 
          }  else if(action.type === "CHANGE_NATCHATHIRAM"){
            return {
                    ...state,
                    natchathiram: action.payload
                } 
          }  else if(action.type === "CHANGE_LAGNAM"){
            return {
                    ...state,
                    lagnam: action.payload
                } 
          }  else if(action.type === "CHANGE_LISTSS"){
            return {
                    ...state,
                    clist: action.payload
                } 
          } else {
               return state
            }
      
      
    //   switch (action.type) {
    //     case 'CHANGE_NAME':
    //         return {
    //             ...state,
    //             name: action.payload
    //         }

    //     case 'CHANGE_OCCUPATION':
    //         return {
    //             ...state,
    //             occupation: action.payload
    //         }
    //     case 'CHANGE_AGE':
    //         return {
    //             ...state,
    //             age: action.payload
    //         }
    //     default:
    //         return state
    // }
}

export default characterReducer;