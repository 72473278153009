import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./login.css";
import Button from '@mui/material/Button';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaEye, FaEyeSlash} from "react-icons/fa";


const Login = () => {

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

  return (
    <div className="">
      <div class="log_content">
<div className="loginbox">

<div className="brandname"><img src={'images/podium/podium_logo.png'} alt="" class="menu-img" /></div>
<div className="signuppo">Sign in to Podium</div>

<div className="threelogin">
<div className="threelogin_one"><img src={'images/podium/google-icon.png'} alt="" class="menu-img" /></div>
<div className="threelogin_two"><img src={'images/podium/facebook-logo.png'} alt="" class="menu-img" /></div>
<div className="threelogin_three"><img src={'images/podium/microsoft-icon.png'} alt="" class="menu-img" /></div>
</div>


<div className="divline"></div>
<div className="divline_or">OR</div>

<form>
  <div className="loginput">
  <input type="text" name="email" placeholder="Email" />
  </div>

  <div className="loginput">
  <input type={passwordType} onChange={handlePasswordChange} value={passwordInput} placeholder="Password" />
  <div class="log_inputs_ic">
  { passwordType==="password"? <FaEyeSlash onClick={togglePassword} className="log_ic" /> :<FaEye onClick={togglePassword} className="log_ic" /> }
  </div>
  </div>

  <div className="frgpass">
      <a href="">Forgot Password</a>
  </div>
  
    <div className="logbut">
      <Button className="btn btn_log" variant="contained">Login</Button>
    </div>

      <div className="alreadyhave">
      Don't have an account yet? <a href="">Sign up</a>
      </div>

</form>
</div>



  </div>
    </div>
  );
};
export default Login;