import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import "shared/navbar/navbar.css";
// import {useSelector, useDispatch} from 'react-redux';
import $ from "jquery";

const Sidebar = () => {

  // const classset = useSelector(state => state.classChanger);
  // const dispatch = useDispatch();

  const changecls = () => {
    $(".headerbar_class").removeClass("headerbar");
    $(".headerbar_class").addClass("headerbar_full");

    $(".sidebar-sec_class").removeClass("sidebar-sec");
    $(".sidebar-sec_class").addClass("sidebar-sec_hide");

    $(".main-panel_class").removeClass("main-panel");
    $(".main-panel_class").addClass("main-panel_full");

    $(".sidebarleft_class").removeClass("sidebarleft");
    $(".sidebarleft_class").addClass("sidebarleft_full");
    $(".menusicon_class").hide();
    $(".menusicon_two_class").show();
    
  };

  const changecls_reverse = () => {
    $(".headerbar_class").removeClass("headerbar_full");
    $(".headerbar_class").addClass("headerbar");

    $(".sidebar-sec_class").removeClass("sidebar-sec_hide");
    $(".sidebar-sec_class").addClass("sidebar-sec");

    $(".main-panel_class").removeClass("main-panel_full");
    $(".main-panel_class").addClass("main-panel");

    $(".sidebarleft_class").removeClass("sidebarleft_full");
    $(".sidebarleft_class").addClass("sidebarleft");
    $(".menusicon_two_class").hide();
    $(".menusicon_class").show();
    
  };


  
  useEffect(() => {
const openMenu = document.querySelector(".menusicon");
const closeMenu = document.querySelector(".menusicon_two");
const menuWrapper = document.querySelector(".sidebar-sec");
    const hasCollapsible = document.querySelectorAll(".has-collapsible");
    // Sidenav Toggle
openMenu.addEventListener("click", function () {
	menuWrapper.classList.add("mini-sidebar");
});

closeMenu.addEventListener("click", function () {
	menuWrapper.classList.remove("mini-sidebar");
});
//     // Collapsible Menu
hasCollapsible.forEach(function (collapsible) {
	collapsible.addEventListener("click", function () {
		collapsible.classList.toggle("active");

// 		// Close Other Collapsible
		hasCollapsible.forEach(function (otherCollapsible) {
			if (otherCollapsible !== collapsible) {
				otherCollapsible.classList.remove("active");
			}
		});
	});
});
  }, []);


return (

<div className="">

<aside className="sidebar-sec_class sidebar-sec">
<div onClick={() => changecls()} className="menusicon_class menusicon"><img src={'images/podium/menu/collapse-arrow.png'} alt="" class="" /> </div> 
<div onClick={() => changecls_reverse()} className="menusicon_two_class menusicon_two"><img src={'images/podium/menu/collapse-arrow-out.png'} alt="" class="" /> </div>

<div className="brandname_logo"><img src={'images/podium/podium_logo.png'} alt="" class="menu-img" /></div>



  {/* <div className="searchmenu">
      <input
        className="form-control menuser"
        type="text"
        placeholder="Search"
      />
      <span className="menu_searchicon"
        ><i className="fa fa-search"></i></span>
  </div> */}

    <div className="sidebar-scroll">
      <ul className="sidebar-menu">
        {/* <li class="">
        <Link to="/wdashboard">WDashboard</Link>
       </li>
       <li class="">
       <Link to="/wfarms">WFarms</Link>
       </li>
       <li class="">
       <Link to="/wbooth">Wbooth</Link>
       </li>
       <li class="">
       <Link to="/wusers">Wusers</Link>
       </li>
       <li class="">
       <Link to="/walerts">WAlerts</Link>
       </li>

       <li class="main-menu has-collapsible">
       <Link to="/wdashboard"><span><img src={'/images/sidenav/dashboard.png'} alt="" class="menu-img" /></span><span>Dashboard</span></Link>
        </li> */}



<li class="main-menu has-collapsible active">
        <Link to="/Home"><span><img src={'images/podium/menu/home.png'} alt="" class="menu-img" /></span><span>Home</span></Link>
        </li>

        <li class="main-menu has-collapsible">
        <Link to="/Dashboard"><span><img src={'images/podium/menu/dashboard.png'} alt="" class="menu-img" /></span><span>Dashboard</span></Link>
        </li>

        <li class="main-menu has-collapsible">
       <Link to="/Assignments"><span><img src={'images/podium/menu/Assignments.png'} alt="" class="menu-img" /></span><span>Assignments</span></Link>
        </li>

        <li class="main-menu has-collapsible">
       <Link to="/Feedback"><span><img src={'images/podium/menu/feedback.png'} alt="" class="menu-img" /></span><span>Feedback</span></Link>
        </li>

        <li class="main-menu has-collapsible">
       <Link to="/Resources"><span><img src={'images/podium/menu/resources.png'} alt="" class="menu-img" /></span><span>Resources</span></Link>
        </li>


       

        {/* <li className="main-menu has-collapsible">
          <a><span></span><span class="arrowupdown">CRM</span></a>
          <ul className="sub-menu menu-child">
            <li className="main-menu">
              <a routerLink="/ipick/Tele-calling" routerLinkActive="router-link-active"  routerLinkActive="active"><span>Registration</span></a>
            </li>
            <li className="main-menu">
              <a routerLink="/ipick/assignParamedicDiagnostics" routerLinkActive="active"><span>Assign</span></a>
            </li> 
        </ul>
        </li> */}

        </ul>
    </div>
    <div className="foot-text">
      <div className="logout_side">
      <li>
       <Link to=""><span><img src={'images/podium/menu/settings.png'} alt="" class="menu-img" /></span><span className="logouml">Settings</span></Link>
      </li> 
      <li>
       <Link to=""><span><img src={'images/podium/menu/help.png'} alt="" class="menu-img" /></span><span className="logouml">Help</span></Link>
      </li> 
      </div>

    </div>
  </aside>

  </div>


);
}
export default Sidebar;



