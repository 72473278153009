import characterReducer from './characterReducer';
import resumeReducer from './resumeReducer';
import counterReducer from './counterReducer';
import classReducer from './classReducer';
import {combineReducers} from 'redux';


//Combine all the sub reducers
const rootReducer = combineReducers({
    characters: characterReducer,
    resumeBasic: resumeReducer,
    myCounter: counterReducer,
    classChanger : classReducer,
})

export default rootReducer;


// For redux example
// https://github.com/Sunnysit/react-redux-hooks-example

// https://redux-hooks-example.netlify.app/