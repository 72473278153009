import React, { Component, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import $ from "jquery";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { FaRegFileExcel } from "react-icons/fa";
import ReactSimplyCarousel from 'react-simply-carousel';

const Dashboard = () => {

    const names = [
        {
            "user": "images/girl.png",
            "title": "Happy speech",
            "created": "April 12",
            "type": "Speech",
            "totaltime": "00:06",
            "fillerwords": "8%",
            "pacing": "106",
        },
        {
          "user": "images/girl.png",
          "title": "Happy speech",
          "created": "April 12",
          "type": "Speech",
          "totaltime": "00:06",
          "fillerwords": "8%",
          "pacing": "106",
      },

      {
        "user": "images/girl.png",
        "title": "speech",
        "created": "April 23",
        "type": "Speech",
        "totaltime": "00:34",
        "fillerwords": "67%",
        "pacing": "112",
    },

    {
      "user": "images/girl.png",
      "title": "Happy",
      "created": "April 13",
      "type": "Speech",
      "totaltime": "00:32",
      "fillerwords": "18%",
      "pacing": "346",
  },
  {
    "user": "images/girl.png",
    "title": "Happy speech",
    "created": "April 12",
    "type": "Speech",
    "totaltime": "00:06",
    "fillerwords": "8%",
    "pacing": "106",
},
{
  "user": "images/girl.png",
  "title": "Happy speech",
  "created": "April 12",
  "type": "Speech",
  "totaltime": "00:06",
  "fillerwords": "8%",
  "pacing": "106",
},

{
"user": "images/girl.png",
"title": "speech",
"created": "April 23",
"type": "Speech",
"totaltime": "00:34",
"fillerwords": "67%",
"pacing": "112",
},

{
"user": "images/girl.png",
"title": "Happy",
"created": "April 13",
"type": "Speech",
"totaltime": "00:32",
"fillerwords": "18%",
"pacing": "346",
},
{
  "user": "images/girl.png",
  "title": "Happy speech",
  "created": "April 12",
  "type": "Speech",
  "totaltime": "00:06",
  "fillerwords": "8%",
  "pacing": "106",
},
{
"user": "images/girl.png",
"title": "Happy speech",
"created": "April 12",
"type": "Speech",
"totaltime": "00:06",
"fillerwords": "8%",
"pacing": "106",
},

{
"user": "images/girl.png",
"title": "speech",
"created": "April 23",
"type": "Speech",
"totaltime": "00:34",
"fillerwords": "67%",
"pacing": "112",
},

{
"user": "images/girl.png",
"title": "Happy",
"created": "April 13",
"type": "Speech",
"totaltime": "00:32",
"fillerwords": "18%",
"pacing": "346",
},

{
  "user": "images/girl.png",
  "title": "Happy speech",
  "created": "April 12",
  "type": "Speech",
  "totaltime": "00:06",
  "fillerwords": "8%",
  "pacing": "106",
},
{
"user": "images/girl.png",
"title": "Happy speech",
"created": "April 12",
"type": "Speech",
"totaltime": "00:06",
"fillerwords": "8%",
"pacing": "106",
},
{
"user": "images/girl.png",
"title": "speech",
"created": "April 23",
"type": "Speech",
"totaltime": "00:34",
"fillerwords": "67%",
"pacing": "112",
},
{
"user": "images/girl.png",
"title": "Happy",
"created": "April 13",
"type": "Speech",
"totaltime": "00:32",
"fillerwords": "18%",
"pacing": "346",
},
   
    ]


    useEffect(() => {
        $( document ).ready(function() {
          $(".pageheads").html("Dashboard");
      });

      }, []);


    useEffect(() => {
      
        if (!$.fn.DataTable.isDataTable("#myTable")) {
            $(document).ready(function() {
              
              
                setTimeout(function() {
                    $("#table").DataTable({
                      initComplete: function (settings, json) {
                        // alert( 'DataTables has finished its initialisation.' );
                        $(".blockdis").show();
                        $(".nonedis").hide();
                        $('#table_wrapper > table').wrap('<div class="fixHead heightclas"></div>');
                      },
                        bDestroy: true,
                        pagingType: "full_numbers",
                        autoWidth: false,
                        language: {
                            search:'',
                            searchPlaceholder: 'Search',
                            paginate: {
                              last: '<img src="images/arrow-right_fast.png">',
                              first: '<img src="images/arrow-left_fast.png">',
                              previous: '<img src="images/arrow-left.png">',
                              next: '<img src="images/arrow-right.png">',
                            },
                          },
                        pageLength: 10,
                        processing: true,
                        dom: '<"dt-top-container"<"dt-len" l><"dt-center-in-div"f><"buttonsclass" B>r>t<"dt-filter-spacer"><ip>',

                        select: {
                            style: "single",
                        },
                        buttons: [
                            { extend: 'excel', 
                            className: 'excelButton', 
                            text: '<img style="width:32px;height:32px;" src="images/grid_table/excel.png">', 
                            titleAttr: 'Export in Excel',
                            title: 'Data export'
                        }
                        ],
                        // buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
                        fnRowCallback: function(
                            nRow,
                            aData,
                            iDisplayIndex,
                            iDisplayIndexFull
                        ) {
                            var index = iDisplayIndexFull + 1;
                            $("td:first", nRow).html(index);
                            return nRow;
                        },
                        lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "10"],
                        ],
                        columnDefs: [{
                            targets: 0,
                            render: function(data, type, row, meta) {
                                return type === "export" ? meta.row + 1 : data;
                            },
                        }, ],
                    });
                }, 1000);
            });
        }


    });

    const showTable = () => {
        try {
          return names.map((item, index) => {
            return (
                <tr>
                <td style={{textAlign:'center'}} className="">{index +1}</td>
                <td className=""><img src={item.user} alt="" className="tblimg" /></td>
               <td className="">{item.title}</td>
               <td className="">{item.created}</td>
               <td className="">{item.type}</td>
               <td className="">{item.totaltime}</td>
               <td className="">{item.fillerwords}</td>
               <td className="">{item.pacing}</td>
</tr>
                );
          });
        } catch (e) {
          // alert(e.message);
        }
      };


  return(
  <div className='pagetp'>
         <div className="table-responsive p-0 pb-2">
         <div className="nonedis Tableprocessing">Loding......</div>
          <div
            style={{ display: "none" }}
            className="blockdis table-responsive p-0 pb-2"
          >

            <div className='tbnam'>
              <div className='tablelbl active_but'>Show all</div>
              <div className='tablelbl'>Speech</div>
              <div className='tablelbl'>Interview</div>
              <div className='tablelbl'>Upload</div>
              <div className='tablelbl'>Meetings (Team)</div>
              <div className='tablelbl'>Meetings (Individual)</div>
            </div>

       <table id="table" style={{minWidth:1070}} className="table table-striped">
           <thead>
               <tr>
               <th style={{width:40}} className="">S/N</th>
               <th style={{width:80}} className="">User</th>
               <th style={{minWidth:100}} className="">Title</th>
               <th style={{width:100}} className="">Created</th>
               <th style={{Width:100}} className="">Type</th>
               <th style={{width:150}} className="">Total Time</th>
               <th style={{Width:150}} className="">% Filler Words</th>
               <th style={{Width:170}} className="">Pacing (words/min)</th>

</tr>
           </thead>

           <tbody>
             {showTable()}
           </tbody>
       </table>

       </div>
           </div>
           </div>


  );
};

export default Dashboard;