import React from "react";
import { FaRegBell } from "react-icons/fa";
import {useSelector, useDispatch} from 'react-redux';
const Topheader = (props) => {
  const classset = useSelector(state => state.classChanger);
  const dispatch = useDispatch();

    return(
        <div className="headerbar_class headerbar">
            <div className="row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="pageheads"></div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="usermanage">

                        <div className="palyup"><img src={'images/podium/menu/upload_video.png'} alt="" class="menu-img" /></div>
                        
                        <div className="rec_ord">
                        <div className="rec"><img src={'images/podium/menu/video_icon.png'} alt="" class="menu-img" /> <span>Record</span></div>
                        </div>
                        
                        
                        
                        <div className="user_man">
                        <div className="">
                        <div class="dropdown">
                            <button class="dropbtn"><img className="headuserss" src={'images/podium/menu/user_icon.png'} /> </button>
                            <div class="dropdown-content">
                            <a href="#">Option 1</a>
                            <a href="#">Option 2</a>
                            <a href="#">Option 3</a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

export default Topheader;
