import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";

const Assignments = () => {


    useEffect(() => {
        $( document ).ready(function() {
          $(".pageheads").html("Assignments");
      });
      }, []);


  return (
    <div className="dhiwise-navigation">
      <h1>Assignments</h1>
    </div>
  );
};
export default Assignments;