import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./forgot.css";
import Button from '@mui/material/Button';

const Forgotpassword = () => {

  return (
    <div className="">
      <div class="log_content">
<div className="loginbox">
<div className="brandname"><img src={'images/podium/podium_logo.png'} alt="" class="" /></div>
<div className="forgotimg"><img src={'images/podium/forgot_p.jpg'} alt="" class="" /></div>
<div className="forgotpastex">Forgot password?</div>


<div className="fornote">Type the email associated with your Podium account and we'll send a link to reset your password</div>



<form>
  <div className="loginput">
  <input type="text" name="email" placeholder="Email" />
  </div>
  
    <div className="logbut">
      <Button className="btn btn_log" variant="contained">Send reset link</Button>
    </div>

      <div className="alreadyhave">
      <a href="">Back to Sign in</a>
      </div>

</form>
</div>



  </div>
    </div>
  );
};
export default Forgotpassword;