import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from "jquery";
const Feedback = () => {


    useEffect(() => {
        $( document ).ready(function() {
          $(".pageheads").html("Feedback");
      });
      }, []);


  return (
    <div className="dhiwise-navigation">
      <h1>Feedback</h1>
    </div>
  );
};
export default Feedback;