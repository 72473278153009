import React, { useState } from 'react';
import Login from "pages/login";
import Assignments from 'pages/Assignments';
import Feedback from 'pages/Feedback';
import Resources from 'pages/Resources';
import Signup from 'pages/Signup';
import Dashboard from 'pages/Dashboard';
import Forgotpassword from 'pages/ForgotPassword';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Sidebar from "shared/navbar/Sidebar";
import {Container} from "reactstrap";
import Topheader from 'components/Topheader/Topheader';
import {useSelector, useDispatch} from 'react-redux';
import { createBrowserHistory } from "history";
// import Header from "shared/navbar/Header";
const ProjectRoutes = () => {
  const classset = useSelector(state => state.classChanger);
  const dispatch = useDispatch();

  const [loggedIn, setLoggedIn] = useState(false);
  var hist = createBrowserHistory();
  return (
    <div>
    <Router history={hist}>
      
    
    { loggedIn ? <div class="wrapper">
      <div class="">
            <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Forgotpassword" element={<Forgotpassword />} />
          </Routes>
      </div>
    </div>
    
    :
    
    <div class="wrapper">
    <Topheader />
      <div className="main-panel_class main-panel">
        <div class="center-content">
        <Container>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Assignments" element={<Assignments />} />
            <Route path="/Feedback" element={<Feedback />} />
            <Route path="/Resources" element={<Resources />} />
            </Routes>
          </Container>
        </div>
      </div>
      <div className="sidebarleft_class sidebarleft">
      <Sidebar />
      </div>
    </div>
    }
        </Router>
    
        </div>
    
  );
};
export default ProjectRoutes;
